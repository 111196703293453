import React from "react";
import { useAppSelector } from "../../state/hooks";

export default function useGTMUserId() {
  const trackingEnabled =
    process.env.NEXT_PUBLIC_ENABLE_THIRD_PARTY_SCRIPTS === "true";
  const userId = useAppSelector((state) => state.hc.healthcareUserId);
  React.useEffect(() => {
    if (trackingEnabled && userId) {
      window.dataLayer.push({
        user_id: userId,
      });
    }
  }, [userId, trackingEnabled]);
}
