import { builder, BuilderComponent, useIsPreviewing } from "@builder.io/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import type { GetStaticPaths, GetStaticProps } from "next";
import DefaultErrorPage from "next/error";
import Head from "next/head";
import React from "react";
import { registerBuilderComponents } from "../components/builderRegistry";
import EnvironmentWarning from "../components/EnvironmentWarning";
import useAmplitudeStart from "../events/hooks/useAmplitudeStart";
import useGTMUserId from "../events/hooks/useGTMUserId";
import usePageView from "../events/hooks/usePageView";
import useHc from "../state/features/hc/useHc";
import { dtcTheme } from "../theme/theme";

// Initialize the Builder SDK with your organization's API Key
// Find the API Key on: https://builder.io/account/settings
if (!process.env.NEXT_PUBLIC_BUILDER_API_KEY) {
  throw new TypeError(
    "Missing environment variable NEXT_PUBLIC_BUILDER_API_KEY"
  );
}
builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY);

export const getStaticProps: GetStaticProps = async ({ params }) => {
  if (!process.env.NEXT_PUBLIC_BUILDER_CACHE_TTL) {
    throw new TypeError(
      "Missing environment variable NEXT_PUBLIC_BUILDER_API_KEY"
    );
  }
  const cacheTTL = parseInt(process.env.NEXT_PUBLIC_BUILDER_CACHE_TTL);

  // Fetch the first page from Builder that matches the current URL.
  // Use the `userAttributes` field for targeting content.
  // For more, see https://www.builder.io/c/docs/targeting-with-builder
  const page = await builder
    .get("page", {
      userAttributes: {
        urlPath: `/${(params?.page as string[] | undefined)?.join("/") || ""}`,
      },
    })
    .toPromise();

  return {
    props: {
      page: page || null,
    },
    revalidate: cacheTTL,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  //  Fetch all published pages for the current model.
  //  Using the `fields` option will limit the size of the response
  //  and only return the `data.url` field from the matching pages.
  const pages = await builder.getAll("page", {
    fields: "data.url", // only request the `data.url` field
    options: { noTargeting: true },
    limit: 0,
  });

  return {
    paths: pages.map((page) => `${page.data?.url}`),
    fallback: "blocking",
  };
};

/** Handles the render loop logic
 *  Renders the Builder.io page with title and metadata along with any content from Builder.io itself
 *  Renders an error page if the user is in Live mode (not previewing through the editor) and there is no route match
 */
export default function Page({
  page,
}: {
  page: React.ComponentProps<typeof BuilderComponent>["content"];
}) {
  //  This flag indicates if you are viewing the page in the Builder editor.
  const isPreviewing = useIsPreviewing();
  useHc();
  usePageView();
  useGTMUserId();
  useAmplitudeStart();

  //  Add your error page here to return if there are no matching
  //  content entries published in Builder.
  if (!page && !isPreviewing) {
    return <DefaultErrorPage statusCode={404} />;
  }

  const robotString = [
    page?.data?.noIndex && "noindex",
    page?.data?.noFollow && "nofollow",
  ]
    .filter((item) => item)
    .join(", ");

  return (
    <>
      <Head>
        {/* Add any relevant SEO metadata or open graph tags here */}
        <title>{page?.data?.title}</title>
        <meta name="description" content={page?.data?.description} />
        {robotString && (
          <meta key="robots" name="robots" content={robotString} />
        )}
      </Head>
      <ThemeProvider theme={dtcTheme}>
        {/* Render the Builder page */}
        <CssBaseline />
        {!isPreviewing && <EnvironmentWarning />}
        <BuilderComponent model="page" content={page} />
      </ThemeProvider>
    </>
  );
}

registerBuilderComponents();
